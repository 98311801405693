import SocketioService from '@/services/socketioService';
import { reactive } from 'vue';
import { parseNode, parseAlarm } from '@/helpers';
import store from '@/store';

const modules = reactive(new Map());
const nodes = reactive(new Map());
const alarms = reactive(new Map());

const state = {
	modules: [],
	moduleScheme: [],
	moduleTypes: [],
	moduleBindings: [],
	modulesFetched: [],
	opcTree: [],
	servers: [],
	ids: {
		servers: null,
	},
	isBusy: {
		modules: false,
	},
	hasLoaded: {
		modules: false,
	}
};

const getters = {
	getModules: (state) => {
		if (state.modules.length > 0) {
			return state.modules;
		}
	},
	getModuleScheme: (state) => {
		if (state.moduleScheme.length > 0) {
			return state.moduleScheme;
		}
	},
	getModuleTypes: (state) => {
		if (state.moduleTypes.length > 0) {
			return state.moduleTypes;
		}
	},
	getModuleBindings: (state) => {
		if (state.moduleBindings.length > 0) {
			return state.moduleBindings;
		}
	},
	getServers: (state) => {
		if (state.servers.length > 0) {
			return state.servers;
		}
	},
	getServersId: (state) => {
		if (state.ids.servers) return state.ids.servers;
	},
	getModule: (state) => (name) => {
		return state.modules.find(item => item.moduleName === name);
	},
	getNode: () => (nodeId) => {
		const node = nodes.get(nodeId);
		if (node) {
			return node;
		}
		return {
			value: store.getters.getNoval,
			unit: '',
			timestamp: '1970-01-01T00:00:00.000Z'
		}
	},
	getModuleFetched: () => (name) => {
		const node = modules.get(name);
		return node;
	},
	// checkNode: ({ dispatch }) => (nodeId) => {
	// 	console.log('NO UNIT');
	// 	return dispatch('opcua/loadNode', nodeId).then(() => {
	// 		console.log('FERTIG');
	// 	});
	// },
	getNodeRaw: () => (nodeId) => {
		return nodes.get(nodeId);
	},
	getNodes: () => {
		return nodes;
	},
	getNodes2: (state) => () => {
		console.log(state.ids);
		return nodes.get('enat0002@|var|CODESYS Control for Linux SL.Application.PLC_PRG.energieManager.volt_400_avg');
	},
	getAlarm: () => (nodeId) => {
		const node = alarms.get(nodeId);
		if (node) {
			return node;
		}
		return {
			value: store.getters.getNoval,
			unit: '',
			timestamp: '1970-01-01T00:00:00.000Z'
		}
	},
	getAlarms: () => {
		return alarms;
	},
	getAlarmsActive: () => {
		let alarmArr = [];
		if (alarms.size > 0) {
			alarms.forEach((value) => {
					if (value.value === 1) {
						alarmArr.push(value);
					}
			});
		}
		return alarmArr.sort((a, b) => b.timestamp - a.timestamp);
	},
	getAlarmCount: () => {
		let count = 0;
		if (alarms.size > 0) {
				alarms.forEach((value) => {
						if (value.value === 1) count += 1;
				});
		}
		if (count < 100) return count;
		else return '99+'
	},
	getOpcuaDirectory: (state) => {
		return state.opcTree;
	},
	getLoadedState: (state) => {
		return state.hasLoaded;
	},
}

const mutations = {
  SET_MODULES(state, data) {
		// data.sort((a, b) => a.label.localeCompare(b.label));
    state.modules = data;
  },
	SET_MODULE_SCHEME(state, data) {
		// data.sort((a, b) => a.label.localeCompare(b.label));
    state.moduleScheme = data;
  },
	SET_MODULE_TYPES(state, data) {
		// data.sort((a, b) => a.label.localeCompare(b.label));
    state.moduleTypes = data;
  },
	SET_MODULE_BINDINGS(state, data) {
		// data.sort((a, b) => a.key.localeCompare(b.key));
    state.moduleBindings = data;
  },
	SET_SERVERS(state, data) {
    state.servers = data.children;
		state.ids.servers = data._id;
  },
	SET_NODE(state, { hash, data }) {
		console.log(hash, data);
		// const oldEntry = nodes.get(hash);
		// const newEntry = Object.assign({}, oldEntry, { value: data.value, timestamp: data.timestamp });
		// console.log(oldEntry, newEntry);
		// nodes.set(hash, parseNode(newEntry));

		// nodes.set(hash, newEntry);

	},
	SET_NODES(state, data) {
		if (Array.isArray(data) && data.length > 0) {
			data.forEach((node) => {
				const hash = `${node.server}@${node.identifier}`;
				const oldEntry = nodes.get(hash);
				const newEntry = Object.assign({}, oldEntry, { value: node.value, valueRaw: node.valueRaw, timestamp: node.timestamp });
				const newObj = parseNode(newEntry);
				if (oldEntry === undefined || oldEntry.value !== newObj.value || oldEntry.valueRaw !== newObj.valueRaw) {
					nodes.set(hash, newObj);
				}
			});
		}
	},
	SET_NODES_FULL(state, data) {
		if (Array.isArray(data) && data.length > 0) {
			data.forEach((node) => {
				const hash = `${node.server}@${node.identifier}`;
				nodes.set(hash, parseNode(node));
				// nodes.set(hash, node);
				// nodes.set(hash, { value: node.value, timestamp: node.timestamp });
			});
		}
	},
	SET_ALARM(state, data) {
		if (typeof data === 'object') {
			//console.log(data);
			if (data.value === -1 || data.value === 1) {
				alarms.set(data.hash, parseAlarm(data));
			} else {
				if (alarms.has(data.hash)) alarms.delete(data.hash);
			}
			//console.log(alarms);
		}
		// if (typeof data === 'object' && data.children) {
			// console.log(data.hash, data.children);
			// const alarmState = data.children.find((item) => item.displayName === 'OUT_AlarmState');
			// const alarmSnooze = data.children.find((item) => item.displayName === 'IN_SnoozeAlarm');
			// const alarmName = data.children.find((item) => item.displayName === 'label');
			// const alarmOut = data.children.find((item) => item.displayName === 'OUT_Alarm');

			// const alarmObj = {
			// 	alarmState: parseNode(alarmState),
			// 	alarmSnooze: parseNode(alarmSnooze),
			// 	alarmName: parseNode(alarmName),
			// 	alarmOut: parseNode(alarmOut)
			// }
			// if (alarmObj.alarmState.value === 0) {
			// 	alarms.delete(data.hash);
			// } else {
			// 	alarms.set(data.hash, alarmObj);
			// }
			// // if (Array.isArray(data.children) && data.children.length > 0) {
			// // 	data.children.forEach((alarmParam) => {
			// // 		alarms.set(data.hash, data.children);
			// // 	});
			// // }
			
			// console.log(alarms.get(data.hash));
			// for (const entry of alarms.entries()) {
			// 	console.log(entry);
			// }
			// // alarms.set(hash, parseNode(newEntry));
			// // nodes.set(hash, newEntry);
		// }
	},
	SET_TEST(state, data) {
		console.log(data);
	},
	SET_OPCUA_DIRECTORY(state, data) {
		data.forEach((tree) => {
			state.opcTree.push(tree.children[0]);
		});
		console.log(state.opcTree);
	},
	setBusyState(state, data) {
		if (data !== null) {
			state.isBusy[`${data.value}`] = data.state;
		}
  },
	setLoadedState(state, data) {
		if (data !== null) {
			state.hasLoaded[`${data.value}`] = data.state;
		}
  },
	SET_MODULES_FETCHED(state, data) {
		if (data !== null) {
			modules.set(data, true);
		}
	},
};

const actions = {
	loadModules: ({ state, commit }, force) => {
		return new Promise((resolve) => {
			console.log(`OPT1`);
			if (force || ((state.modules.length === 0) && !state.isBusy.modules)) {
				commit('setBusyState', {value: 'modules', state: true});
				console.log(`OPT2`);
				SocketioService.getModules((err, response) => {
					if (!err && response) {
						console.log(`OPT3`);
						commit('SET_MODULES', response);
						commit('setBusyState', {value: 'modules', state: false});
						commit('setLoadedState', {value: 'modules', state: true});
						resolve(state.modules);
					}
				});
			}
			if (((state.modules.length > 0) && !state.isBusy.modules)) {
				console.log(`OPT4`);
				resolve(state.modules);
			}
			// resolve(state.modules);
		});
	},
	loadModuleScheme: ({ state, commit }, force) => {
		if (force || state.moduleScheme.length === 0) {
			SocketioService.getModuleScheme((err, response) => {
				if (!err && response) {
					commit('SET_MODULE_SCHEME', response);
				}
			});
		}
	},
	loadModuleTypes: ({ state, commit }, force) => {
		if (force || state.moduleTypes.length === 0) {
			SocketioService.getModuleTypes((err, response) => {
				if (!err && response) {
					commit('SET_MODULE_TYPES', response);
				}
			});
		}
	},
	loadModuleBindings: ({ state, commit }, force) => {
		if (force || state.moduleBindings.length === 0) {
			SocketioService.getModuleBindings((err, response) => {
				if (!err && response) {
					commit('SET_MODULE_BINDINGS', response);
				}
			});
		}
	},
	loadOpcuaServers: ({ state, commit }, force) => {
		if (force || state.servers.length === 0) {
			SocketioService.getOpcuaServers((err, response) => {
				if (!err && response) {
					commit('SET_SERVERS', response);
				}
			});
		}
	},
	loadModule: async ({ state, commit }, name) => {
		return new Promise((resolve) => {
			if (typeof name === 'string' && name.length > 0) {
				const module = state.modules.find(item => item.moduleName === name);
				if (module) {
					resolve(module);
				} else {
					SocketioService.getModules((err, response) => {
						if (!err && response) {
							commit('SET_MODULES', response);
							const module = state.modules.find(item => item.moduleName === name);
							resolve(module);
						}
					});
				}
			}
		});
	},
	loadNode: ({ commit }, nodeId) => {
		return new Promise((resolve) => {
			if (nodeId) {
				SocketioService.getRedisHash(nodeId, (err, response) => {
					if (!err && response) {
						console.log(nodeId);
						commit('SET_NODE', { hash: nodeId, data: response });
						resolve(response);
					}
				});
			}
		});
	},
	loadNodeOnce: ({ commit }, nodeId) => {
		return new Promise((resolve) => {
			const node = nodes.get(nodeId);
			if (nodeId && (!node || Number.isNaN(node.value))) {
				SocketioService.getRedisNodesParamScaled(nodeId, (err, response) => {
					// console.log(response);
					if (err || !response) {
						console.log(`[OPCUA] error fetching node: ${err}`);
					} else {
						commit('SET_NODES_FULL', response);
						resolve(response);
					}
				});
			} else {
				resolve(node);
			}
		});
	},
	// loadNodeOnce: ({ commit }, nodeId) => {
	// 	return new Promise((resolve) => {
	// 		const node = nodes.get(nodeId);
	// 		console.log(nodeId), 
	// 		if (nodeId && node && !node.unit) {
	// 			SocketioService.getRedisHash(nodeId, (err, response) => {
	// 				if (!err && response) {
	// 					commit('SET_NODE', { hash: nodeId, data: response });
	// 					resolve(response);
	// 				}
	// 			});
	// 		}
	// 	});
	// },
	loadAlarms: ({ commit }) => {
		return new Promise((resolve) => {
			SocketioService.getRedisNodesParamScaled(`*!alarmEntry!*`, (err, response) => {
        // console.log(response);
        if (err) {
          console.log(`[OPCUA] error fetching alarms: ${err}`);
        } else {
          if (Array.isArray(response)) {
            // console.log(response);
            if (response.length > 0) {
              response.forEach((alarmEntry) => {
                if (typeof alarmEntry.value === 'string') alarmEntry.value = parseInt(alarmEntry.value);
                if (typeof alarmEntry.alarmPrior === 'string') alarmEntry.alarmPrior = parseInt(alarmEntry.alarmPrior);
								if (typeof alarmEntry.timestamp === 'string') alarmEntry.timestamp = new Date(alarmEntry.timestamp);
								commit('SET_ALARM', alarmEntry);
              });
            }
					}
        }
      }).then(() => {
				resolve(alarms);
        console.log('[OPCUA] alarms finished');
      }); 
			// SocketioService.getRedisAlarms((err, response) => {
			// 	if (!err && response) {
			// 		commit('SET_ALARM', response);
			// 		resolve(response);
			// 	}
			// });
		});
	},
	setNode: ({ commit }, payload) => {
		const key = `${payload.server}@${payload.identifier}`;
		const data = {
			value: payload.value,
			timestamp: payload.timestamp
		}
		commit('SET_NODE', { key, data });
	},
	setNodes: ({ commit }, payload) => {
		commit('SET_NODES', payload);
	},
	setNodesFull: ({ commit }, payload) => {
		commit('SET_NODES_FULL', payload);
	},
	setAlarm: ({ commit }, payload) => {
		if (payload.value && payload.alarmPrior) {
			if (typeof payload.value === 'string') payload.value = parseInt(payload.value);
      if (typeof payload.alarmPrior === 'string') payload.alarmPrior = parseInt(payload.alarmPrior);
			if (typeof payload.timestamp === 'string') payload.timestamp = new Date(payload.timestamp);
		}
		commit('SET_ALARM', payload);
	},
	loadOpcuaDirectory: ({ state, commit }, force) => {
		return new Promise((resolve) => {
			if (state.opcTree.length === 0 || force) {
				SocketioService.getOpcuaBrowse(null, (err, response) => {
					if (!err && response !== null && response.length > 0) {
						commit('SET_OPCUA_DIRECTORY', response);
						resolve(response);
					}
				});
			} else {
				resolve(state.opcTree);
			}
		});
	},
	setModuleFetched: ({ commit }, payload) => {
		commit('SET_MODULES_FETCHED', payload);
	},
};

export default {
  namespaced: true,
  state,
	getters,
  mutations,
  actions,
};