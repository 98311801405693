import SocketioService from '@/services/socketioService';
import { formatDateGui, dhm } from '@/helpers';
import store from '@/store';
import { reactive } from 'vue';

const hbData = reactive({
	dateTime: {},
	connection: {},
	heartbeat: {},
	tokenData: {},
});

const state = {
  dateTime: {},
	connection: {},
	heartbeat: {},
	tokenData: {},
	installation: {
		client: {
			name: null,
			desc: null,
			mail: null,
			id: null,
		},
		location: {
			address: null,
			zip: null,
			country: null,
			timezone: null,
			longitude: null,
			latitude: null,
			height: null
		},
		system: {
			label: null,
			background: null,
			foreground: null
		}
	},
	network: {},
	app: {},
	copyright: {
		copyrightTxt: null,
		copyrightYear: null,
		copyrightLink: null,
		versionInfo: null,
		version: null
	},
	viewMenu: [],
	changeLog: [],
};

const getters = {
	// getDateTime: (state) => state.dateTime,
	getDateTime: () => hbData.dateTime,
	// getConnection: (state) => state.connection,
	getConnection: () => hbData.connection,
	// getHeartbeat: (state) => state.heartbeat,
	getHeartbeat: () => hbData.heartbeat,
	// getTokenData: (state) => state.tokenData,
	getTokenData: () => hbData.tokenData,
	getInstallation: (state) => state.installation,
	getNetwork: (state) => state.network,
	getApp: (state) => state.app,
	getCopyright: (state) => state.copyright,
	currentTimeFormatted: () => {
		if (hbData.dateTime.timestamp) return formatDateGui(hbData.dateTime.timestamp);
		else return store.getters.getNoval;
	},
	startTimeFormatted: () => {
		if (hbData.dateTime.started) return formatDateGui(hbData.dateTime.started);
		else return store.getters.getNoval;
	},
	runTimeFormatted: () => {
		if (hbData.dateTime.runtime) return dhm(hbData.dateTime.runtime);
		else return store.getters.getNoval;
	},
	connectionCount: () => {
		if (hbData.connection.count) return hbData.connection.count;
		else return store.getters.getNoval;
	},
	sessionId: () => {
		if (hbData.connection.sessionId) return hbData.connection.sessionId;
		else return store.getters.getNoval;
	},
	// currentTimeFormatted: (state) => {
	// 	if (state.dateTime.timestamp) return formatDateGui(state.dateTime.timestamp);
	// 	else return store.getters.getNoval;
	// },
	// startTimeFormatted: (state) => {
	// 	if (state.dateTime.started) return formatDateGui(state.dateTime.started);
	// 	else return store.getters.getNoval;
	// },
	// runTimeFormatted: (state) => {
	// 	if (state.dateTime.runtime) return dhm(state.dateTime.runtime);
	// 	else return store.getters.getNoval;
	// },
	// connectionCount: (state) => {
	// 	if (state.connection.count) return state.connection.count;
	// 	else return store.getters.getNoval;
	// },
	// sessionId: (state) => {
	// 	if (state.connection.sessionId) return state.connection.sessionId;
	// 	else return store.getters.getNoval;
	// },
	clientName: (state) => {
		if (state.installation.client.name) return state.installation.client.name;
		else return store.getters.getNoval;
	},
	clientDesc: (state) => {
		if (state.installation.client.desc) return state.installation.client.desc;
		else return store.getters.getNoval;
	},
	clientId: (state) => {
		if (state.installation.client.id) return state.installation.client.id;
		else return store.getters.getNoval;
	},
	clientEmail: (state) => {
		if (state.installation.client.email) return state.installation.client.email;
		else return store.getters.getNoval;
	},
	systemLabel: (state) => {
		if (state.installation.system.label) return state.installation.system.label;
		else return store.getters.getNoval;
	},
	systemLabelBgCol: (state) => {
		if (state.installation.system.background) return state.installation.system.background;
		else return store.getters.getNoval;
	},
	systemLabelFgCol: (state) => {
		if (state.installation.system.foreground) return state.installation.system.foreground;
		else return store.getters.getNoval;
	},
	versionInfo: (state) => {
		if (state.copyright.versionInfo) return state.copyright.versionInfo;
		else return store.getters.getNoval;
	},
	version: (state) => {
		if (state.copyright.version) return state.copyright.version;
		else return store.getters.getNoval;
	},
	copyrightTxt: (state) => {
		if (state.copyright.copyrightTxt) return state.copyright.copyrightTxt;
		else return store.getters.getNoval;
	},
	copyrightYear: (state) => {
		if (state.copyright.copyrightYear) return state.copyright.copyrightYear;
		else return store.getters.getNoval;
	},
	copyrightLink: (state) => {
		if (state.copyright.copyrightLink) return state.copyright.copyrightLink;
		else return store.getters.getNoval;
	},
	viewMenu: (state) => {
		if (state.viewMenu) return state.viewMenu;
		else return [];
	},
	changeLog: (state) => {
		if (state.changeLog) return state.changeLog;
		else return [];
	},
}

const mutations = {
  SET_DATETIME(state, data) {
    // state.dateTime = data;
		hbData.dateTime = data;
  },
	SET_CONNECTION(state, data) {
    // state.connection = data;
		hbData.connection = data;
  },
	SET_HEARTBEAT(state, data) {
    // state.heartbeat = data;
		hbData.heartbeat = data;
  },
	SET_TOKENDATA(state, data) {
    // state.tokenData = data;
		hbData.tokenData = data;
  },
	SET_INSTALLATION(state, data) {
    state.installation = data;
  },
	SET_NETWORK(state, data) {
    state.network = data;
  },
	SET_APP(state, data) {
    state.app = data;
  },
	SET_COPYRIGHT(state, data) {
    state.copyright = data;
  },
	SET_VIEWMENU(state, data) {
    state.viewMenu = data;
  },
	SET_CHANGELOG(state, data) {
    state.changeLog = data;
  },
};

const actions = {
	saveHeartbeat:  ({ commit }, data) => {
		if (data.dateTime) {
			commit('SET_DATETIME', data.dateTime);
		}
		if (data.connection) {
			commit('SET_CONNECTION', data.connection);
		}
		if (data.heartbeat) {
			commit('SET_HEARTBEAT', data.heartbeat);
		}
		if (data.tokenData) {
			commit('SET_TOKENDATA', data.tokenData);
		}
	},
	loadSystemInstallation: ({ commit }) => {
		return new Promise((resolve) => {
			SocketioService.getSystemInstallation((err, response) => {
				if (!err) {
					commit('SET_INSTALLATION', response);
					resolve(response);
				}
			});
		});
	},
	loadSystemCopyright: ({ commit }) => {
		return new Promise((resolve) => {
			SocketioService.getSystemCopyright((err, response) => {
				if (!err) {
					commit('SET_COPYRIGHT', response);
					resolve(response);
				}
			});
		});
	},
	loadViewMenu: ({ commit }) => {
		return new Promise((resolve) => {
			SocketioService.getViewMenu((err, response) => {
				if (!err) {
					commit('SET_VIEWMENU', response);
					resolve(response);
				}
			});
		});
	},
	loadChangeLog: async (context, {result}) => {
		SocketioService.getChangeLog((err, response) => {
			if (!err) {
				// commit('SET_CHANGELOG', null);
				result(response);
			}
		});
	},
};

export default {
  namespaced: true,
  state,
	getters,
  mutations,
  actions,
};