import SocketioService from '@/services/socketioService';
// import { formatDateGui, dhm } from '@/helpers';
// import store from '@/store';

const state = {
	groups: [],
	users: [],
};

const getters = {
	getUserGroups: (state) => {
		if (state.groups.length > 0) {
			return state.groups;
		}
	},
	getUserList: (state) => {
		if (state.groups.length > 0) {
			return state.groups;
		}
	},
}

const mutations = {
  setUserGroups(state, data) {
		data.sort((a, b) => a.name.localeCompare(b.name));
    state.groups = data;
  },
	setUserList(state, data) {
		// data.sort((a, b) => a.name.localeCompare(b.name));
    state.users = data;
  },
};

const actions = {
	loadUserGroups: ({ state, commit }, force) => {
		if (force || state.groups.length === 0) {
			SocketioService.getUserGroupList((err, response) => {
				if (!err && response) {
					commit('setUserGroups', response);
				} else {
					this.$toast.add({
            severity: "error",
            summary: `Fehler bei Server Anfrage`,
            detail: `Gruppenliste konnte nicht geladen werden`,
            life: 3000,
          });
				}
			});
		}
	},
	loadUserList: ({ state, commit }, force) => {
		if (force || state.groups.length === 0) {
			SocketioService.getUserList((err, response) => {
				if (!err && response) {
					commit('setUserList', response);
				} else {
					this.$toast.add({
            severity: "error",
            summary: `Fehler bei Server Anfrage`,
            detail: `Benutzerliste konnte nicht geladen werden`,
            life: 3000,
          });
				}
			});
		}
	},
};

export default {
  namespaced: true,
  state,
	getters,
  mutations,
  actions,
};