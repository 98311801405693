import SocketioService from '@/services/socketioService';
// import { formatDateGui, dhm } from '@/helpers';
import store from '@/store';

const state = {
	units: [],
	category: [],
	departments: [],
	icons: [],
	isBusy: {
		units: false,
		category: false,
		departments: false,
		icons: false
	},
	ids: {
		units: null,
		category: null,
		departments: null,
		icons: null
	},
	alarmPrior: [
		{ label: 'Priorität 1', value: 1 },
		{ label: 'Priorität 2', value: 2 },
		{ label: 'Priorität 3', value: 3 },
	],
	synoptocode: [
		{ label: 'ohne Funktion', value: 0 },
		{ label: 'Niederschlag', value: 40 },
		{ label: 'leichter Niesel', value: 51 },
		{ label: 'Niesel', value: 52 },
		{ label: 'starker Niesel', value: 53 },
		{ label: 'leichter Regen', value: 61 },
		{ label: 'Regen', value: 62 },
		{ label: 'starker Regen', value: 63 },
		{ label: 'leichter Schneeregen', value: 67 },
		{ label: 'Schneeregen', value: 68 },
		{ label: 'Schneefall', value: 70 },
		{ label: 'leichter Schneefall', value: 71 },
		{ label: 'mittelstarker Schneefall', value: 72 },
		{ label: 'starker Schneefall', value: 73 },
		{ label: 'Hagel', value: 89 }
	],
	dayphase: [
		{ label: 'astronomische Morgendämmerung', value: 0 },
		{ label: 'nautische Morgendämmerung', value: 1 },
		{ label: 'bürgerliche Morgendämmerung', value: 2 },
		{ label: 'Morgen', value: 3 },
		{ label: 'Vormittag', value: 4 },
		{ label: 'Mittag', value: 5 },
		{ label: 'Nachmittag', value: 6 },
		{ label: 'Abend', value: 7 },
		{ label: 'bürgerliche Abenddämmerung', value: 8 },
		{ label: 'nautische Abenddämmerung', value: 9 },
		{ label: 'astronomische Abenddämmerung', value: 10 },
		{ label: 'Nacht', value: 11 }
	],
	fertilizer: [
		{ label: 'Düngerezept 1', value: 0 },
		{ label: 'Düngerezept 2', value: 1 },
		{ label: 'Düngerezept 3', value: 2 },
		{ label: 'Düngerezept 4', value: 3 },
		{ label: 'Düngerezept 5', value: 4 },
		{ label: 'Düngerezept 6', value: 5 },
		{ label: 'Düngerezept 7', value: 6 },
		{ label: 'Düngerezept 8', value: 7 }
	],
	fertiRatio: [
		{ label: 'Aus', value: 0 },
		{ label: '1 Anteil', value: 1 },
		{ label: '2 Anteile', value: 2 },
		{ label: '3 Anteile', value: 3 },
		{ label: '4 Anteile', value: 4 },
		{ label: '5 Anteile', value: 5 },
		{ label: '6 Anteile', value: 6 },
		{ label: '7 Anteile', value: 7 },
		{ label: '8 Anteile', value: 8 },
		{ label: '9 Anteile', value: 9 }
	],
	irrigationGroups: [
		{ label: 'Gruppe 1', value: 0 },
		{ label: 'Gruppe 2', value: 1 },
		{ label: 'Gruppe 3', value: 2 },
		{ label: 'Gruppe 4', value: 3 },
		{ label: 'Gruppe 5', value: 4 },
		{ label: 'Gruppe 6', value: 5 },
		{ label: 'Gruppe 7', value: 6 },
		{ label: 'Gruppe 8', value: 7 }
	],
	heatFwSeq: [
		{ label: 'Aus', value: 0 },
		{ label: '1. Sequenz', value: 1 },
		{ label: '2. Sequenz', value: 2 }
	],
	ventsOrientation: [
		{ label: 'N/S', value: 0 },
		{ label: 'NO/SW', value: 1 },
		{ label: 'O/W', value: 2 },
		{ label: 'SO/NW', value: 3 }
	],
	ventsControlType: [
		{ label: 'Laufzeit', value: 0 },
		{ label: 'Stellungsgeber', value: 1 }
	],
	weekDays: [
		{ label: 'Montag', value: 1 },
		{ label: 'Dienstag', value: 2 },
		{ label: 'Mittwoch', value: 3 },
		{ label: 'Donnerstag', value: 4 },
		{ label: 'Freitag', value: 5 },
		{ label: 'Samstag', value: 6 },
		{ label: 'Sonntag', value: 7 }
	],
};

const getters = {
	getUnits: (state) => {
		if (state.units.length > 0) {
			return state.units;
		}
	},
	getCategory: (state) => {
		if (state.category.length > 0) {
			return state.category;
		}
	},
	getOneCategory: (state) => (name) => {
		if (state.category.length > 0) {
			if (typeof name === 'string') {
				return state.category.find(item => item.key === name);
			}
		}
		return store.getters.getNoval;
	},
	getDepartments: (state) => {
		if (state.departments.length > 0) {
			return state.departments;
		}
	},
	getOneDepartment: (state) => (number) => {
		if (state.departments.length > 0) {
			if (typeof number !== 'string') number = number.toString();
			return state.departments.find(item => item.key === number);
		}
		return store.getters.getNoval;
	},
	getIcons: (state) => {
		if (state.icons.length > 0) {
			return state.icons;
		}
	},
	getUnitsId: (state) => {
		if (state.ids.units) return state.ids.units;
	},
	getCategoryId: (state) => {
		if (state.ids.category) return state.ids.category;
	},
	getDepartmentsId: (state) => {
		if (state.ids.departments) return state.ids.departments;
	},
	getIconsId: (state) => {
		if (state.ids.icons) return state.ids.icons;
	},
	getAlarmPrior: (state) => {
		if (state.alarmPrior) return state.alarmPrior;
	},
	getSynoptoCode: (state) => {
		if (state.synoptocode) return state.synoptocode;
	},
	getDayPhase: (state) => {
		if (state.dayphase) return state.dayphase;
	},
	getFertilizer: (state) => {
		if (state.fertilizer) return state.fertilizer;
	},
	getFertiRatio: (state) => {
		if (state.fertiRatio) return state.fertiRatio;
	},
	getIrrigationGroups: (state) => {
		if (state.irrigationGroups) return state.irrigationGroups;
	},
	getHeatFwSeq: (state) => {
		if (state.heatFwSeq) return state.heatFwSeq;
	},
	getVentsOrientation: (state) => {
		if (state.ventsOrientation) return state.ventsOrientation;
	},
	getVentsControlType: (state) => {
		if (state.ventsControlType) return state.ventsControlType;
	},
	getWeekdays: (state) => {
		if (state.weekDays) return state.weekDays;
	},
	getOneWeekday: (state) => (number) => {
		if (typeof number !== 'number') number = Number(number);

		return state.weekDays.find(item => item.value === number).label;
	},
}

const mutations = {
  setUnitTypes(state, data) {
		data.children.sort((a, b) => a.label.localeCompare(b.label));
    state.units = data.children;
		state.ids.units = data._id;
  },
	setCategoryTypes(state, data) {
		data.children.sort((a, b) => a.label.localeCompare(b.label));
    state.category = data.children;
		state.ids.category = data._id;
  },
	setDepartmentTypes(state, data) {
		data.children.sort((a, b) => a.key.localeCompare(b.key));
    state.departments = data.children;
		state.ids.departments = data._id;
  },
	setIconTypes(state, data) {
		data.children.sort((a, b) => a.key.localeCompare(b.key));
    state.icons = data.children;
		state.ids.icons = data._id;
  },
	setBusyState(state, data) {
		if (data !== null) {
			state.isBusy[`${data.value}`] = data.state;
		}
  },
};

const actions = {
	loadUnitTypes: ({ state, commit }, force) => {
		return new Promise((resolve) => {
			if ((force || state.units.length === 0) && !state.isBusy.units) {
				SocketioService.getUnitTypes((err, response) => {
					if (!err && response) {
						commit('setUnitTypes', response);
						commit('setBusyState', {value: 'units', state: false});
						resolve(response);
					}
				});
			}
		});
	},
	loadCategoryTypes: ({ state, commit }, force) => {
		return new Promise((resolve) => {
			if ((force || state.category.length === 0) && !state.isBusy.category) {
				commit('setBusyState', {value: 'category', state: true});
				SocketioService.getCategoryTypes((err, response) => {
					if (!err && response) {
						commit('setCategoryTypes', response);
						commit('setBusyState', {value: 'category', state: false});
						resolve(response);
					}
				});
			}
		});
	},
	loadDepartmentTypes: ({ state, commit }, force) => {
		return new Promise((resolve) => {
			if ((force || state.departments.length === 0) && !state.isBusy.departments) {
				commit('setBusyState', {value: 'departments', state: true});
				SocketioService.getDepartmentTypes((err, response) => {
					if (!err && response) {
						commit('setDepartmentTypes', response);
						commit('setBusyState', {value: 'departments', state: false});
						resolve(response);
					}
				});
			}
		});
	},
	loadIconTypes: ({ state, commit }, force) => {
		return new Promise((resolve) => {
			if ((force || state.icons.length === 0) && !state.isBusy.icons) {
				commit('setBusyState', {value: 'icons', state: true});
				SocketioService.getIconTypes((err, response) => {
					if (!err && response) {
						commit('setIconTypes', response);
						commit('setBusyState', {value: 'icons', state: false});
						resolve(response);
					}
				});
			}
		});
	}
};

export default {
  namespaced: true,
  state,
	getters,
  mutations,
  actions,
};